import { React, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

const CheckStepNumber = ({
    showRegistrationStep,
    showEmpDashboard,
    ID
}) => {
    let navigate = useNavigate()
    // const navigateTo = (URL) => {
    // navigate(URL)
    // console.log("URL:", URL);
    // // document.getElementById('modalCloseButton').click()
    // }
    // console.log({ showRegistrationStep, ID })
    console.log('enterd in step number js');
    console.log('showEmpDashboard', showEmpDashboard);



    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getCourseID = searchParams.get('id');
    const isEmbedded = searchParams.get('src') === 'embeded';
    const retrievedID = localStorage.getItem('certCourseID');

    useEffect(() => {
        console.log('show registration steps in checkstepnumber: ', showRegistrationStep)
        // if (showRegistrationStep === null) {
        //     console.log('in the zeroth if statement')
        //     navigateTo('/')
        // }
        // else 
        if (showRegistrationStep === 0) {
            console.log('in the first if statement')
            console.log(showRegistrationStep, ID)
            if (ID) {
                console.log('ID in step cout comp. :', ID)
                navigate('/')
            } else {
                navigate('/welcome')
            }
        }
        // if (showRegistrationStep === -1) {
        //     navigate('/candidate-login')
        // }
        if (showRegistrationStep === -2) {
            navigate('/')
        }
        if (showRegistrationStep === 1) {
            navigate('/complete-profile?step=step1')
        }
        if (showRegistrationStep === 2) {
            navigate('/complete-profile?step=step2')
        }
        if (showRegistrationStep === 3) {
            navigate('/complete-profile?step=step3')
        }
        if (showRegistrationStep === 5) {
            console.log('/emp-candidate-list if runnign')
            navigate('/emp-candidate-list')
        }
        // else if (showRegistrationStep === 3) {
        //     navigateTo('/complete-profile?step=step3')
        // }
    }, [isEmbedded, showRegistrationStep, showEmpDashboard])

    return (
        <div>

        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const {
        showRegistrationStep,
        ID
    } = auth

    return {
        showRegistrationStep,
        ID
    }
}

export default connect(mapStateToProps)(CheckStepNumber)
