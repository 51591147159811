const config = {
    // appUrl : "http://localhost:3000",
    // apiUrl : "https://api.scopehai.com"
    // apiUrl : "https://www.scopehai.com/api",
    apiUrl : "https://beta.scopehai.com/api"
    // apiUrl : "http://localhost:4444/api"
    // apiUrl : "http://localhost:4444/api"

}
export default config;
